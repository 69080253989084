<template>
  <div id="e-course-solution">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-multiline">
        <div class="column is-8 is-offset-2">
          <p class="title is-5 has-text-right">
            {{ schoolClassName }} - {{ subjectName }}
          </p>
          <p class="subtitle is-6 has-text-right">{{ eCourseTopicName }}</p>
        </div>
        <div class="column is-8 is-offset-2">
          <div class="list">
            <div class="block">
              <div
                v-for="(item, index) in questions"
                :key="index + 1"
                class="e-courses-list"
              >
                <div class="question-container">
                  <div class="question-index">
                    <span class="number-circle">{{ index + 1 }}</span>
                  </div>
                  <div :id="`question-holder-${index}`" class="question"></div>
                </div>
                <div class="options-container">
                  <ol type="A">
                    <template v-for="(option, optionIndex) in item.options">
                      <li :key="option">
                        <b-radio
                          v-model="solutions[item.id]"
                          :name="`${index}_${optionIndex + 1}_option`"
                          :native-value="`${optionIndex + 1}`"
                          @change.native="checkAnswer(item.id)"
                          :disabled="submitted"
                        >
                          <p class="option" v-html="option" />
                        </b-radio>
                      </li>
                    </template>
                  </ol>
                </div>
                <div
                  class="has-text-right"
                  v-if="submitted && Object.keys(answers).length != 0"
                >
                  <template v-if="answers[item.id].passed">
                    <span class="mr-5">score {{ answers[item.id].score }}</span>
                    <fa-icon class="has-text-success" icon="check" size="lg" />
                  </template>
                  <template v-else>
                    <span class="mr-5"
                      >score {{ (answers[item.id].score = 0) }}</span
                    >
                    <fa-icon class="has-text-danger" icon="times" size="lg" />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-8 is-offset-2" v-if="!submitted">
          <div class="buttons is-right">
            <button
              type="submit"
              class="button btn-120 is-primary is-capitalized"
              @click="submitSolution()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchUser, fetchTerm } from '@/assets/js/app_info.js'
import { crudNotification } from '../../assets/js/notification'

export default {
  data() {
    return {
      pageTitle: 'E Course Solution',
      eCourseWorkSolution: [],
      questions: [],
      solutions: {},
      answers: {},
      schoolClassName: '',
      subjectName: '',
      eCourseTopicName: '',
      submitted: false,
    }
  },
  watch: {
    eCourseWorkSolution(data) {
      if (!data) {
        this.submitSolution()
      } else {
        this.eCourseTopicName = data.eCourseWork.eCourseTopic.name
        this.schoolClassName =
          data.eCourseWork.eCourseTopic.eCourse.schoolClass.name
        this.subjectName = data.eCourseWork.eCourseTopic.eCourse.subject.name

        this.questions = data.eCourseWork.questions

        this.submitted = data.submitted

        this.solutions = data.solutions

        this.setQuestionsAndOptions()
      }
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)

    fetchUser().then((user) => {
      this.user = user
      this.role = user.role
      this.schoolId = user.school_id
      this.$store.commit('setSubMenus', [
        {
          name: 'E Courses',
          route: `/school/${this.schoolId}/e_courses`,
        },
      ])
    })

    this.$apollo.addSmartQuery('eCourseWorkSolution', {
      query: gql`
        query ECourseWorkSolutionForStudent(
          $eCourseWorkId: Int!
          $studentId: Int!
        ) {
          eCourseWorkSolution(
            eCourseWorkId: $eCourseWorkId
            studentId: $studentId
          ) {
            id
            solutions
            score
            submitted
            eCourseWork {
              id
              courseType
              duration
              questions
              eCourseTopic {
                name
                eCourse {
                  schoolClass {
                    name
                  }
                  subject {
                    name
                  }
                }
              }
              questions
            }
          }
        }
      `,
      variables() {
        return {
          eCourseWorkId: parseInt(this.$route.params.e_course_work_id),
          studentId: parseInt(this.$route.params.student_id),
        }
      },
      errors(error) {
        console.log(error)
      },
      fetchPolicy: 'no-cache',
    })

    this.$apollo.queries.eCourseWorkSolution.refetch()
  },
  methods: {
    setQuestionsAndOptions() {
      this.$nextTick(() => {
        this.questions.forEach((question, index) => {
          this.populate(index, question)
        })
      })

      this.questions.forEach((item) => {
        let [question, raw_options] = item.question
          .split(/<div>|<\/div>/)
          .filter((r) => {
            if (r.length > 0) {
              return r
            }
          })
        if (raw_options) {
          let options = raw_options
            .split(/<ol>|<li>|<\/li>|<\/ol>|<br>/)
            .filter((r) => {
              if (r !== '\n\t') {
                return r
              }
            })
          item.question = question
          item.options = options
        }
      })
    },
    populate(index, question) {
      if (!question) return
      const div = document.getElementById(`question-holder-${index}`)
      if (div) {
        div.style.wordBreak = 'break-word'
        div.innerHTML = question.question
      }
    },
    checkAnswer(id) {
      this.answers = {}

      const question = this.questions.filter(
        (question) => question.id === id
      )[0]

      let passed = this.solutions[id] === question.answer
      let score = question.score
      this.answers[id] = { passed: passed, score: score }
    },
    submitSolution() {
      const score = []
      Object.keys(this.solutions).forEach((id) => {
        this.checkAnswer(id)
        let answer = this.answers[id]
        if (answer.passed) score.push(parseInt(answer.score))
      })

      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateECourseWorkSolution(
              $studentId: Int!
              $eCourseWorkId: Int!
              $solutions: JSON!
              $score: Int!
            ) {
              updateECourseWorkSolution(
                input: {
                  studentId: $studentId
                  eCourseWorkId: $eCourseWorkId
                  solutions: $solutions
                  score: $score
                }
              ) {
                eCourseWorkSolution {
                  id
                  solutions
                }
                errors
              }
            }
          `,
          variables: {
            studentId: parseInt(this.$route.params.student_id),
            eCourseWorkId: parseInt(this.$route.params.e_course_work_id),
            solutions: this.solutions,
            score: score.reduce((a, b) => {
              return a + b
            }, 0),
          },
        })
        .then((response) => {
          let message = 'Successfully saved.'
          const data = response.data.updateECourseWorkSolution
          if (Object.keys(data.eCourseWorkSolution.solutions).length == 0) {
            message = 'Successfully started.'
          }
          crudNotification(data.errors, message).then(() => {
            this.$apollo.queries.eCourseWorkSolution.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.e-courses-list {
  font-size: 0.8em;
  border-bottom: orange 1px solid;
  padding: 10px 10px;
}

.question-container {
  display: flex;
  margin-bottom: 5px;
}

.question-index {
  flex: 1 3%;
  font-weight: bold;
}

.question {
  flex: 1 97%;
  font-weight: bold;
}

.question-option {
  flex: 1 97%;
}

.options-container {
  padding-left: 16px;
}

.question-footer {
  display: flex;
  padding: 8px;
}

.question-footer > a {
  flex: 1;
}

.question-footer > a:nth-child(1) {
  flex: 2 75%;
}

.list {
  height: 900px;
  overflow-y: scroll;
  padding: 8px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
